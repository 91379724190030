import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/About.vue";
import HomeNew from "@/views/HomeNew.vue";
import AboutNew from "@/views/AboutNew.vue";
import NotFound from "@/views/NotFound.vue";
import Calendar from "@/views/Calendar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeNew,
    // component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // component: About,
    component: AboutNew,
  },
  // {
  //   path: "/calendar",
  //   name: "calendar",
  //   component: Calendar,
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
