<template>
  <div>
    <LvDialog
      :header="currentEvent.title"
      v-model="displayBasic"
      :style="{ maxWidth: '900px', width: '100%' }"
    >
      <div class="event-details">
        <div class="event-date-wrapper">
          <LvBadge color="success" size="sm">
            {{
              new Date(currentEvent.start).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </LvBadge>
          <template v-if="currentEvent.end">
            <span> - </span>
            <LvBadge color="success" size="sm">
              {{
                new Date(currentEvent.end).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
            </LvBadge>
          </template>
          <span style="font-weight: 600">
            {{ getDays(currentEvent.start) }}</span
          >
        </div>
        <p v-if="currentEvent.extendedProps.description">
          {{ currentEvent.extendedProps.description }}
        </p>
        <template v-if="currentEvent.extendedProps.location">
          <LvButton
            :label="currentEvent.extendedProps.location"
            size="sm"
            icon="light-icon-map-pin"
            rounded
            class="lv--danger"
          />
        </template>
        <template v-if="currentEvent.extendedProps.images">
          <h4 class="event-info-heading">Photos</h4>
          <Swiper
            :pagination="{
              clickable: true,
            }"
            :navigation="true"
            :space-between="50"
            :autoHeight="true"
            :modules="modules"
          >
            <SwiperSlide v-for="(item, ind) in swiperData" :key="ind">
              <img :src="item" alt="img" />
            </SwiperSlide>
          </Swiper>
        </template>
      </div>
    </LvDialog>

    <div style="padding: 100px 0px">
      <div class="container">
        <FullCalendar :options="calendarOptions">
          <template v-slot:eventContent="arg">
            {{ arg.event.title }}
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import LvDialog from "lightvue/dialog";
import LvButton from "lightvue/button";
import LvBadge from "lightvue/badge";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

export default {
  components: {
    FullCalendar,
    LvDialog,
    LvButton,
    LvBadge,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Navigation],
      swiperData: [
        require("@/assets/images/DSC00394.jpg"),
        require("@/assets/images/DSC00409.jpg"),
        require("@/assets/images/DSC00452.jpg"),
        require("@/assets/images/DSC00548.jpg"),
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin], //, timeGridPlugin, listPlugin
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "title",
          end: "prev,next",
        },
        //  ,dayGridMonth,timeGridWeek,timeGridDay,listMonth
        dayMaxEvents: 2,
        eventClick: this.handleEventClick,
        events: [
          {
            title: "Flowers Day (Early years)",
            start: "2024-09-06",
            end: null,
            description: "This is description.",
            location: "Block 4-A Gulshan-e-Iqbal, Karachi",
            images: "dsad",
          },
          {
            title: "Student Body Council Election Campaign",
            start: "2024-09-22",
            end: "2024-09-24T23:00:00",
            location: "Dream Palace, Federal B Area",
          },
          {
            title: "School re-opens",
            start: "2024-09-01",
          },
        ],
      },
      currentEvent: {
        extendedProps: {},
      },
      displayBasic: false,
    };
  },
  methods: {
    getDays(val) {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let valid = new Date(val);
      valid.setHours(0, 0, 0, 0);

      let diff = valid.getTime() - currentDate.getTime();
      let oneDay = 24 * 60 * 60 * 1000;

      let daysLeft = 0;
      let text = "days remaining";
      if (diff > 0) {
        daysLeft = Math.ceil(diff / oneDay);
      }
      if (daysLeft == 0) return "";
      if (daysLeft == 1) text = "day remaining";
      return `(${daysLeft} ${text})`;
    },

    handleEventClick(ev) {
      // { event: ev }
      // console.log(ev)
      this.currentEvent = ev.event;
      this.displayBasic = true;
      console.log(this.currentEvent);
      // alert("date click! " + arg.dateStr);
    },
  },
};
</script>
<style>
.fc .fc-view-harness {
  min-height: 650px;
}
.fc .fc-col-header-cell-cushion {
  color: #555;
  text-decoration: none;
  line-height: 1.4;
}
.fc .fc-col-header-cell-cushion:hover {
  color: #555;
  text-decoration: none;
}
.fc .fc-daygrid-day-number {
  color: #b9b9c3;
  cursor: pointer;
}
.fc .fc-button-group {
  gap: 1rem;
}
.fc .fc-button-primary,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
  border-radius: 0.25rem !important;
}
.fc .fc-button-primary:hover {
  background-color: white;
  border-color: var(--primary);
  color: var(--primary);
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #f8f8f8 !important;
  /* background-color: rgba(40, 199, 111, 0.12); */
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #555;
}
.fc-h-event {
  background-color: var(--primary);
  border: none;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  white-space: normal;
}
.fc .fc-daygrid-day {
  padding: 5px;
}
.fc .fc-daygrid-more-link {
  color: #444;
}

.event-details {
  padding-top: 1rem;
}
.event-date-wrapper {
  margin-bottom: 0.75rem;
}
.event-details p {
  font-size: 16px;
  line-height: 1.2;
  word-wrap: break-word;
}
.event-info-heading {
  /* margin-top: 0; */
  margin-bottom: 0.75rem;
}
:root {
  --swiper-theme-color: var(--primary);
}
/* .swiper-button-prev,
.swiper-button-next {
  color: var(--primary);
} */
</style>
<!-- <style scoped>
.swiper-slide img {
  aspect-ratio: 2;
  object-fit: contain;
}
</style> -->
